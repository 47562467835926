.CardMethod,
.CashMethod {
  position: relative;
  max-width: 490px;
  margin: 0 auto;

  &-CardIcons,
  &-FieldIcon {
    position: absolute;
    top: 0;
    right: 0;

    span {
      width: 34px;
      height: 25px;
      margin-left: 5px;
      display: inline-block;
      background-repeat: no-repeat;
      background-image: image('card-icons.svg');
    }

    &__visa {
      background-position-x: 0;
    }

    &__mastercard {
      background-position-x: -34px;
    }

    &__amex {
      background-position-x: -68px;
    }

    &__discover {
      background-position-x: -102px;
    }
  }

  &-FieldIcon {
    top: 36px;
    left: 5px;
    right: inherit;

    &__unknown {
      width: 30px !important;
      background-position-x: -136px;
    }
  }

  .form {

    &-control {
      font-size: 16px;
      font-weight: bold;
      height: calc(1.594em + 0.75rem + 4px);

      &:focus {
        box-shadow: none;
      }

      &#cardNumber {
        padding-left: 50px;
      }
    }
  }

  &-Security {

    &__Hint {
      border: 0;
      border-radius: 8px;

      &.bs-popover-top > .arrow::before {
        border: 0;
      }

      &--Btn {
        width: 14px;
        height: 14px;
        line-height: 14px;
        position: absolute;
        left: 95px;
        top: 5px;
        font-size: 12px;
        padding: 0 !important;
        border-radius: 100%;
      }
    }
  }
}
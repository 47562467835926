.Dashboard {
  min-height: 100vh;
  background-color: $gray-1000;
  
  &-Header {
    padding: 20px 0;
    
    &__UserNav {
      padding: 0;
      margin: 15px 0;
      text-align: right;

      li {
        list-style: none;
        display: inline-block;

        .dropdown {

          &-toggle {
            padding: 0;
            border: 0;
            color: $black-100;
            font-size: 14px;
            font-weight: 500;
            background-color: transparent;
              
            &:focus {
              box-shadow: none;
            }
          }

          .btn-primary.dropdown-toggle,
          .btn-primary.dropdown-toggle:active, 
          .btn-primary.dropdown-toggle:not(:disabled):not(.disabled):active {
            color: $black;
            background-color: transparent;

            &:focus {
              box-shadow: none;
            }
          }

          &-menu {

            a {
              font-size: 14px;
              font-weight: normal;

              &:active {
                background-color: $primary;
              }
            }
          }
        }

        a {
          color: $black-100;
          font-weight: 500;

          &:hover {
            color: $dark;
            text-decoration: none;
          }
        }
      }

      &--Avatar {
        width: 28px;
        height: 28px;
        display: inline-block;
        border-radius: 100%;
      }

      &--Name {
        margin-left: 8px;

        @include media-breakpoint-down(xs) {
          display: none;
        }
      }
    }
  }

  &-Content {

    &Area {
      min-height: 500px;
      margin-bottom: 50px;
    }

    &Sidebar {
      padding: 20px;
      margin: 50px 0 30px 0;
      background-color: $white;

      &__Nav {
        margin: 0;
        padding: 0;

        li {
          width: 100%;
          display: block;
          list-style: none;
          padding: 0;

          a {
            border-radius: 4px;
            padding: 10px;
            color: #7a809a;
            font-size: 16px;
            @include flexbox();
            @include align-items(center);

            svg {
              color: #cdd9e5;
              margin-right: 10px;
            }

            &:hover {
              opacity: 1;
              color: $black;
              text-decoration: none;
              @include transition(opacity 0.3s);
            }

            &.active {
              color: $primary;
              font-weight: 600;
              background-color: rgba($color: $primary, $alpha: 0.10);

              svg {
                color: $primary;
              }
            }
          }
        }
      }
    }

    &Body {
      width: 100%;
      height: 100%;
      
      &__Form {

        &--SignIn {
          width: 380px;
          max-height: 100%;
          margin: 0 auto;
        }

        &--Table {

          th,
          td {

            &:last-child {
              text-align: right;
            }
          }
        }
      }

      &__Header {

        &--Title {
          color: $dark;
          font-size: 24px;
          font-weight: 600;
          margin-bottom: 20px;
        }
      }

      &__TableData {

        .table {

          tr {

            th {
              color: $gray-1100;
              font-weight: 600;
              
              &.totalCol,
              &.tableNav {
                text-align: right;
              }
            }

            td {
              color: $gray-1100;
              vertical-align: middle;

              strong {
                font-weight: 600;
              }

              small {
                display: block;
                font-size: 12px;
              }

              .badge {
                font-size: 12px;
                font-size: 400;
              }
            }
          }
        }
              
        .totalCol,
        .tableNav {
          text-align: right;

          .dropdown {

            .btn {
              color: $gray-1100;
              padding: 0;
              border: 0;
              background-color: transparent;

              &:after {
                content: none;
              }

              &:hover {
                color: $gray-900;
              }

              &:focus,
              &:active {
                box-shadow: none;
              }
            }
          }
        }
      }

      &__CardItem {
        border-radius: 8px;

        &--Header {
          color: $primary;
          font-size: 18px;
        }

        &--CountItem {
          font-size: 21px;
          font-weight: bold;
        }

        &--IconBox {
          width: 40px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          margin-left: auto;
          border-radius: 100px;
          background-color: $light;
        }
      }
    }
  }
}

.BookingOverView {

  &-Table {

    tbody {

      tr {

        &:first-child {
          
          td {
            border-top: 0;
          }
        }
      }
    }

    tbody,
    tfoot {

      td,
      th {

        &:last-child {
          text-align: right;
        }
      }
    }
  }
}

.BookingView,
.BookingEdit {

  .modal {

    &-title {
      font-size: 14px;
      font-weight: normal;
    }
  }
}
@mixin transform($transforms) {
	-webkit-transform: $transforms;
	   -moz-transform: $transforms;
	    -ms-transform: $transforms;
	        transform: $transforms;
}

@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin justify-content($justify) {
  -webkit-justify-content: $justify;
     -moz-justify-content: $justify;
      -ms-justify-content: $justify;
          justify-content: $justify;
            -ms-flex-pack: $justify;
}

@mixin align-items($align) {
  -webkit-align-items: $align;
     -moz-align-items: $align;
      -ms-align-items: $align;
       -ms-flex-align: $align;
          align-items: $align;
}

@mixin flex($fg: 1, $fs: null, $fb: null) {
  $fg-boxflex: $fg;
  
	@if type-of($fg) == 'list' {
		$fg-boxflex: nth($fg, 1);
	}

	-webkit-box-flex: $fg-boxflex;
	    -webkit-flex: $fg $fs $fb;
	   -moz-box-flex: $fg-boxflex;
	       -moz-flex: $fg $fs $fb;
	        -ms-flex: $fg $fs $fb;
	            flex: $fg $fs $fb;
}

@mixin rotate($degrees) {
	-webkit-transform: rotate($degrees);
		 -moz-transform: rotate($degrees);
			-ms-transform: rotate($degrees);
			 -o-transform: rotate($degrees);
					transform: rotate($degrees);
}